<template>
  <v-row>
    <v-col cols="12" v-if="ga.headers.length > 0">
      <v-card :class="classNames" outlined>
        <v-card-title>GA</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="ga.headers"
            :items="ga.items"
            :loading="loading"
            class="elevation-1"
            disable-sort
            hide-default-footer
            disable-pagination
            disable-filtering
          />
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" v-if="wyzistat.headers.length > 0">
      <v-card :class="classNames" outlined>
        <v-card-title>Wyzistat</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="wyzistat.headers"
            :items="wyzistat.items"
            :loading="loading"
            class="elevation-1"
            disable-sort
            hide-default-footer
            disable-pagination
            disable-filtering
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped></style>

<script>
import { computed, reactive, watch } from "@vue/composition-api";

import useAxios from "@/hooks/useAxios";
import useProxy from "@/hooks/useProxy";

import { getStartOfMonth, getEndOfMonth } from "@/utils/dates";
import { formatNumberCompact } from "@/utils/formatting";

export default {
  name: "Results",
  components: {},
  props: {
    classNames: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const { store } = useProxy();
    const { axiosGet } = useAxios();

    const page = reactive({
      loading: false,
    });

    const ga = reactive({
      headers: [],
      items: [],
    });

    const wyzistat = reactive({
      headers: [],
      items: [],
    });

    // =>  METHODS
    const formatResult = (data) => {
      return data.map((item) => {
        for (const key of Object.keys(item)) {
          if (key === "Site") {
            continue;
          }

          item[key] = formatNumberCompact(item[key]);
        }

        return item;
      });
    };

    const getHeaders = (data) => {
      if (data.length <= 0) {
        return [];
      }

      return Object.keys(data[0]).map((key) => ({ text: key, value: key }));
    };

    const getData = async () => {
      if (
        applyFilters.value &&
        !page.loading &&
        startDate.value &&
        startDate.value.length === 10 &&
        endDate.value &&
        endDate.value.length === 10
      ) {
        page.loading = true;
        const {
          data: { ga: ga_data, wyzistat: wyzistat_data },
        } = await axiosGet("/audience/monthly-data", {
          start_date: startDate.value,
          end_date: endDate.value,
        });

        ga.items = formatResult(ga_data);
        ga.headers = getHeaders(ga_data);
        console.log(ga_data, ga);

        wyzistat.items = formatResult(wyzistat_data);
        wyzistat.headers = getHeaders(wyzistat_data);
        console.log(wyzistat_data, wyzistat);

        page.loading = false;

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        store.dispatch("common/updateApplyFilters", false);
      }
    };

    // =>  COMPUTED
    const applyFilters = computed(
      () => store.getters["common/getApplyFilters"]
    );
    const startDate = computed(() =>
      getStartOfMonth(`${store.getters["audience/getDates"][0]}-01`)
    );
    const endDate = computed(() =>
      getEndOfMonth(`${store.getters["audience/getDates"][1]}-01`)
    );

    // =>  WATCH
    watch(applyFilters, () => {
      getData();
    });

    // =>  HOOKS;

    // =>  DATA
    return {
      loading: page.loading,
      ga,
      wyzistat,
    };
  },
};
</script>
