<template>
  <div>
    <p class="text-2xl mb-6">Audience par mois</p>
    <v-card class="mb-6 pa-2" outlined>
      <v-row class="ma-3">
        <v-col cols="12">
          <filters></filters>
        </v-col>
      </v-row>
    </v-card>

    <results-table classNames="mb-6 pa-2" />

    <progress-bar :download="download" />
    <dot-menu
      position="page-top-right"
      :isLoading="isLoading"
      @exportExcel="exportExcel"
      :excelButton="{
        excelData: excelButton.data,
        filename: 'audience_monthly_data.xlsx',
        disabled: isLoading,
      }"
    />
  </div>
</template>

<script>
import {
  computed,
  onMounted,
  reactive,
  set,
  watch,
} from "@vue/composition-api";

import Filters from "@/components/audience/monthly-data/filters/Filters.vue";
import ResultsTable from "@/components/audience/monthly-data/tables/Results.vue";
import DotMenu from "@/components/common/menus/DotMenu";
import ProgressBar, {
  download_initial_state,
} from "@/components/common/ProgressBar";

import useAxios from "@/hooks/useAxios";
import useProxy from "@/hooks/useProxy";

import { getStartOfMonth, getEndOfMonth } from "@/utils/dates";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "MonthlyData",
  components: {
    DotMenu,
    Filters,
    ProgressBar,
    ResultsTable,
  },
  setup(props, { emit }) {
    const { route, store } = useProxy();
    const { isLoading, axiosGet } = useAxios();

    const page = reactive({
      loading: false,
    });
    const download = reactive(download_initial_state());
    const excelButton = reactive({
      data: new Blob([""], { type: "text/plain" }),
    });

    // =>  METHODS
    const exportExcel = async () => {
      if (
        !startDate.value ||
        startDate.value.length !== 10 ||
        !endDate.value ||
        endDate.value.length !== 10
      ) {
        return;
      }

      page.loading = true;
      download.running = true;
      const { data } = await axiosGet(
        "/audience/monthly-data/export_xlsx",
        {
          start_date: startDate.value,
          end_date: endDate.value,
        },
        {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            download.percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        }
      );

      excelButton.data = data;
      page.loading = false;
    };

    // =>  COMPUTED
    const startDate = computed(() =>
      getStartOfMonth(`${store.getters["audience/getDates"][0]}-01`)
    );
    const endDate = computed(() =>
      getEndOfMonth(`${store.getters["audience/getDates"][1]}-01`)
    );

    // =>  WATCH
    watch(
      () => download.percentCompleted,
      (newValue) => {
        if (newValue >= 100) {
          setTimeout(() => {
            const initialState = download_initial_state();
            for (const [key, value] of Object.entries(initialState)) {
              set(download, key, value);
            }
            for (const key of Object.keys(download)) {
              if (!(key in initialState)) {
                del(download, key);
              }
            }
          }, 500);
        }
      }
    );

    // =>  HOOKS
    onMounted(async () => {
      saveRmraView(route);
    });

    // =>  DATA
    return {
      isLoading,
      excelButton,
      exportExcel,
      download,
    };
  },
};
</script>

<style scoped></style>
